import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import('../views/AccountLogin.vue')
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomePage.vue'),
        children: [
            {
                path: '/index',
                name: 'index',
                meta: {
                    title: 'app列表'
                },
                component: () => import('../views/app/AppHandle.vue')
            },
            // app相关
            {
                path: '/app/list',
                name: 'app',
                meta: {
                    title: 'app列表'
                },
                component: () => import('../views/app/AppHandle.vue')
            },
            // 账号相关
            {
                path: '/account/list',
                name: '账号列表',
                meta: {
                    title: '账号列表'
                },
                component: () => import('../views/account/AccountHandle.vue')
            },
            // 数据上报相关
            {
                path: '/report/account-info/list',
                name: '上报账号数据列表',
                meta: {
                    title: '上报账号数据列表'
                },
                component: () => import('../views/data-factory/ReportAccountInfo.vue')
            },
            {
                path: '/report/device-info/list',
                name: '上报设备数据列表',
                meta: {
                    title: '上报设备数据列表'
                },
                component: () => import('../views/data-factory/ReportDeviceInfo.vue')
            },
            {
                path: '/report/event/list',
                name: '上报事件数据列表',
                meta: {
                    title: '上报事件数据列表'
                },
                component: () => import('../views/data-factory/ReportEvents.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // 修改页面
    if (to.meta.title) {
        document.title = `${import.meta.env.VITE_APP_TITLE} - ` + to.meta.title;
    }
    // 执行登录页面
    if (to.path === '/login') {
        return next();
    }

    // 获取token
    const token = sessionStorage.getItem('token');
    if (!token) {
        return next({
            path: '/login',
            query: { redirect: to.fullPath } // 登录后重定向回目标页面
        });
    }

    return next();
})

export default router;
