import { createApp } from 'vue';
import { createPinia } from 'pinia';
import horizontalScroll from 'el-table-horizontal-scroll';
import installElementPlus from '@/plugins/element.js';
import router from '@/router';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import goPath from '@/router/common.js';
import '@/assets/css/icon.css';
import '@/assets/css/color-dark.css';
import '@/assets/css/main.css';


const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
installElementPlus(app)
app.use(pinia)
    .use(router)
    .use(horizontalScroll)
app.config.globalProperties.$goPath = goPath
app.mount('#app')
